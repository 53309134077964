import React from "react";
import { withRouter } from "react-router-dom";

import Header from "components/Header";
import HeaderLinks from "components/HeaderLinks";
import IconOptaCut from "@material-ui/icons/Dashboard";
import IconOptaFloor from "mdi-material-ui/Factory";
import IconOptaPlan from "@material-ui/icons/DateRange";

const links = [
  { to: "/", name: "Home" },
  {
    name: "Solutions",
    links: [
      { to: "/optacut", name: "OptaCut", icon: <IconOptaCut /> },
      { to: "/optafloor", name: "OptaFloor", icon: <IconOptaFloor /> },
      { to: "/optaplan", name: "OptaPlan", icon: <IconOptaPlan /> },
    ],
  },
  // { to: "/about_us", name: "About Us" },
  { to: "/blogs", name: "Blog" },
  { to: "/contact", name: "Contact" },
  { to: "/demo", name: "Request Demo", action: true },
];

const AppHeader = ({ location }) =>
  location.pathname === "/login" ? null : (
    <Header
      bgColor={"rose"}
      color={
        location.pathname === "/" ||
        // location.pathname === "/optaplan" ||
        // location.pathname === "/tna" ||
        // location.pathname === "/optafloor" ||
        // location.pathname === "/spms" ||
        (location.pathname.includes("/blogs") &&
          !(location.pathname.includes("/create") || location.pathname.includes("/edit"))) ||
        location.pathname === "/contact" ||
        location.pathname === "/demo"
          ? "white"
          : "dark"
      }
      logoVariant={
        location.pathname === "/" ||
        // location.pathname === "/optaplan" ||
        // location.pathname === "/tna" ||
        // location.pathname === "/optafloor" ||
        // location.pathname === "/spms" ||
        (location.pathname.includes("/blogs") &&
          !(location.pathname.includes("/create") || location.pathname.includes("/edit"))) ||
        location.pathname === "/contact" ||
        location.pathname === "/demo"
          ? "dark"
          : "white"
      }
      rightLinks={<HeaderLinks links={links} dropdownHoverColor="info" />}
      fixed
      changeColorOnScroll={{
        height: 400,
        color: "white",
        bgColor: "dark",
        logoVariant: "dark",
      }}
    />
  );

export default withRouter(AppHeader);

/**
     <Header
      // transparent={
      //   location.pathname === "/" ||
      //   location.pathname === "/optacut" ||
      //   location.pathname === "/contact" ||
      //   location.pathname === "/demo"
      // }
      transparent={true}
      color={
        location.pathname === "/" ||
        location.pathname === "/optacut" ||
        location.pathname === "/optaplan" ||
        location.pathname === "/contact" ||
        location.pathname === "/demo"
          ? // location.pathname.includes("/blogs")
            "transparent"
          : "white"
      }
      // color={"transparent"}
      rightLinks={<HeaderLinks links={links} dropdownHoverColor="info" />}
      fixed
      changeColorOnScroll={{
        height: 400,
        color: "primary"
      }}
    />
 */
