import { appId } from "../constants";

const getHeaders = () => {
  let headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  const accessToken = localStorage[`access_token_${appId}`];
  if (accessToken) {
    headers["Authorization"] = `Bearer ${accessToken}`;
  }
  return headers;
};

export default getHeaders;
