import React from "react";
import PropTypes from "prop-types";
import clsx from "classnames";

// @material-ui/core components
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import { container } from "assets/jss/material-kit-pro-react.jsx";
import colors from "utils/colors";

const useStyles = makeStyles(() => ({
  container: props => ({
    ...container,
    zIndex: "12",
    //     background: "linear-gradient(to right, #5457ff, #54c9ff)",
    backgroundColor: "#2c353c",
    color: "#FFFFFF",
    height: props.height,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  }),
  title: {
    color: "white",
    textAlign: "center",
  },
  subtitle: {
    color: "white",
    textAlign: "center",
    margin: "1rem 0",
  },
  textCenter: {
    textAlign: "center",
  },
  button: props => ({
    //     backgroundColor: "#405162",
    backgroundColor: props.color,
    marginTop: "2em",
    fontSize: "1.1em",
    fontWeight: "400",
    //     color: "white",
    "&:hover,&:focus": {
      //       backgroundColor: "#D3D3D3",
      backgroundColor: props.hoverColor,
    },
    textTransform: "none",
  }),
  gridRoot: {
    paddingBottom: "2em",
  },
  marginTop: {
    marginTop: "2.5em",
  },
}));

const CallToAction = ({ title, subtitle, actionLabel, onClick, height = 300, color = "skyBlue" }) => {
  const classes = useStyles({ color: colors[color]?.main, hoverColor: colors[color]?.light, height });
  return (
    <div className={classes.container}>
      <div>
        {/* <h2 className={clsx(classes.title, classes.alignCenter)}>{title}</h2>
        {subtitle && <h3 className={clsx(classes.subtitle, classes.alignCenter)}>{subtitle}</h3>} */}

        <Typography className={clsx(classes.title, classes.alignCenter)} variant="h4">
          {title}
        </Typography>

        {subtitle && (
          <Typography className={clsx(classes.subtitle, classes.alignCenter)} variant="subtitle1">
            {subtitle}
          </Typography>
        )}
      </div>
      <div>
        <Button variant="contained" className={classes.button} onClick={onClick}>
          {actionLabel}
        </Button>
      </div>
    </div>
  );
};

CallToAction.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  actionLabel: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
};

export default CallToAction;
