import React, { Component } from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = theme => ({
  content: {
    padding: theme.spacing(4),
  },
  appBarSpacer: theme.mixins.toolbar,
});

class NotFound extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.content}>
        <div className={classes.appBarSpacer} />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(NotFound);
