import React from "react";

// react component for creating beautiful carousel
import Carousel from "react-slick";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/GridContainer.jsx";
import GridItem from "components/GridItem.jsx";
import Card from "components/Card.jsx";
import CardBody from "components/CardBody.jsx";
import CardAvatar from "components/CardAvatar.jsx";
import Muted from "components/Typography/Muted.jsx";

import karan from "assets/img/testimonials/karan.jpg";
import sumit from "assets/img/testimonials/sumit.jpg";
import srinivas from "assets/img/testimonials/srinivas.jpg";
// import christian from "assets/img/faces/christian.jpg";

import {
  container,
  mlAuto,
  mrAuto,
  title,
  description,
  cardTitle
} from "assets/jss/material-kit-pro-react.jsx";

const styles = {
  container,
  mlAuto,
  mrAuto,
  title,
  description,
  cardTitle,
  sectionDark: {
    // ...sectionDark,
    backgroundColor: "#2c353c",
    backgroundSize: "550% 450%"
  },
  cardDescription: {
    ...description
  },
  cardCategory: {
    ...description
  },
  testimonials: {
    padding: "20px 0",
    "& $cardDescription": {
      fontStyle: "italic",
      color: "#FFFFFF"
    },
    "& $cardTitle": {
      color: "#FFFFFF"
    },
    "& .slick-dots": {
      display: "none !important"
    }
  },
  card: {
    display: "flex",
    margin: "60px auto",
    "& $cardDescription": {
      fontStyle: "italic"
    }
  },
  cardItem: {
    margin: "0 auto",
    maxWidth: 650
  }
  // carousel: {
  //   "& button::before": {
  //     color: "black"
  //   }
  // }
};

function SectionTestimonials({ ...props }) {
  const { classes, ...rest } = props;
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false
  };

  return (
    <div className="cd-section" {...rest}>
      <div className={`${classes.testimonials} ${classes.sectionDark}`}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Carousel {...settings} className={classes.carousel}>
                <div>
                  <Card testimonial plain className={classes.card}>
                    <div className={classes.cardItem}>
                      <CardAvatar testimonial plain>
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <img src={sumit} alt="..." />
                        </a>
                      </CardAvatar>
                      <CardBody plain>
                        <h5 className={classes.cardDescription}>
                          "I always thought OptaCut will only optimize our
                          fabric utilization, but after using it for 6 months,
                          it gave us something which we never imagined. Not only
                          it reduced our fabric wastages from 1.6% to 0.6%, but
                          the data which came out was beyond our imagination.
                          OptaCut analytics tells you every possible aspect of
                          fabric saving that you miss during the busy schedule
                          of operations."
                        </h5>
                        <h4
                          className={classes.cardTitle}
                          style={{ margin: "20px 0 0 0" }}
                        >
                          Sumit Kharb
                        </h4>
                        <Muted>
                          <h6 style={{ margin: 0 }}>
                            Head of Manufacturing @{" "}
                            <a href="https://cieltextile.com/">
                              Laguna Clothing LLP
                            </a>
                            , Bangalore
                          </h6>
                        </Muted>
                      </CardBody>
                    </div>
                  </Card>
                </div>
                <div>
                  <Card testimonial plain className={classes.card}>
                    <div className={classes.cardItem}>
                      <CardAvatar testimonial plain>
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <img src={srinivas} alt="..." />
                        </a>
                      </CardAvatar>
                      <CardBody plain>
                        <h5 className={classes.cardDescription}>
                          "OptaCut has helped us optimize fabric consumption &
                          gain better internal controls. We have been benefited
                          by optimizing fabric, live control on fabric usage,
                          FCR, mill performance & accurate fabric estimation.
                          OptaCut success lies in the requirement elicitation,
                          and consulting approach showcased by the Jaza-soft
                          team to understand the shop floor processes, issues,
                          and provide effective timely solutions."
                        </h5>
                        <h4
                          className={classes.cardTitle}
                          style={{ margin: "20px 0 0 0" }}
                        >
                          Al Srinivas
                        </h4>
                        <Muted>
                          <h6 style={{ margin: 0 }}>
                            Director - Operations @{" "}
                            <a href="http://www.ranger-apparel.com/">
                              Ranger Apparel
                            </a>
                            , Bangalore
                          </h6>
                        </Muted>
                      </CardBody>
                    </div>
                  </Card>
                </div>
                <div>
                  <Card testimonial plain className={classes.card}>
                    <div className={classes.cardItem}>
                      <CardAvatar testimonial plain>
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <img src={karan} alt="..." />
                        </a>
                      </CardAvatar>
                      <CardBody plain>
                        <h5 className={classes.cardDescription}>
                          "Jaza Software has designed a cut plan
                          software-OptaCut which suits our workflow and has
                          helped to optimize fabric consumption through cut plan
                          features resulting in efficient inventory management
                          and better control over our fabric partners."
                        </h5>
                        <h4
                          className={classes.cardTitle}
                          style={{ margin: "20px 0 0 0" }}
                        >
                          Karan Saraogi
                        </h4>
                        <Muted>
                          <h6 style={{ margin: 0 }}>
                            Managing Director @{" "}
                            <a href="http://www.ranger-apparel.com/">
                              Ranger Apparel
                            </a>
                            , Bangalore
                          </h6>
                        </Muted>
                      </CardBody>
                    </div>
                  </Card>
                </div>
                {/* <div>
                  <Card testimonial plain className={classes.card}>
                    <div className={classes.cardItem}>
                      <CardAvatar testimonial plain>
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <img src={christian} alt="..." />
                        </a>
                      </CardAvatar>
                      <CardBody plain>
                        <h5 className={classes.cardDescription}>
                          "Thank you Anna for the invite thank you to the whole
                          Vogue team Called I Miss the Old Kanye At the God's
                          last game Chop up the soul Kanye. I promise I will
                          never let the people down. I want a better life for
                          all!!! Pablo Pablo Pablo Pablo! Thank you Anna for the
                          invite thank you to the whole Vogue team."
                        </h5>
                        <h4 className={classes.cardTitle}>
                          Christian Louboutin
                        </h4>
                        <Muted>
                          <h6>Designer @ Louboutin & Co.</h6>
                        </Muted>
                      </CardBody>
                    </div>
                  </Card>
                </div> */}
              </Carousel>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(SectionTestimonials);
