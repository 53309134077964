const darkGrey = { light: "#405162", main: "#2c353c", dark: "#222b34" };
const skyBlue = { light: "#63acee", main: "#4ca0eb", dark: "#1e88e5" };
const lightGreen = { light: "#4ad7ae", main: "#2bc397", dark: "#26ae87" };
const tomatoRed = { light: "#ee91a8", main: "#e86584", dark: "#e44f73" };
const lemon = { light: "#edd373", main: "#e7c546", dark: "#ddb51d" };
const orange = { light: "#FF9032", main: "#fe7500", dark: "#E56900" };
const purple = { light: "#916DD0", main: "#7446C4", dark: "#673AB6" };
const grey = { light: "#6a859d", main: "#5e788f", dark: "#546b80" };
const green = { light: "#67BB6B", main: "#4aa54e", dark: "#429346" };
const red = { light: "#FF8388", main: "#ff5057", dark: "#FF373F" };
const rose = { light: "#F06393", main: "#eb3573", dark: "#E91E63" };
const turqoise = { light: "#00C3DB", main: "#00acc1", dark: "#0095A8" };

export default {
  lightGreen,
  tomatoRed,
  lemon,
  orange,
  purple,
  grey,
  skyBlue,
  darkGrey,
  green,
  red,
  rose,
  turqoise,
};
