/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";

// @material-ui/core components
import makeStyles from "@material-ui/core/styles/makeStyles";
// @material-ui/icons

// core components
import GridContainer from "components/GridContainer.jsx";
import GridItem from "components/GridItem.jsx";
import Card from "components/Card.jsx";
import CardBody from "components/CardBody.jsx";
import CardHeader from "components/CardHeader.jsx";
import Button from "components/Button.jsx";

import { container, mlAuto, mrAuto, title, cardTitle, description } from "assets/jss/material-kit-pro-react.jsx";
import colors from "utils/colors";

import { appUrl } from "../../../App";

const productList = mediaUrl => [
  {
    to: "/spms",
    src: `${mediaUrl}/optafloor.png`,
    alt: "OptaFloor - Smart Production Management System",
    cardTitle: "OptaFloor",
    desc: "A smart manufacturing solution to optimize shop floor productivity.",
  },
  {
    to: "/optacut",
    src: `${mediaUrl}/optacut.png`,
    alt: "OptaCut - Cut Plan & Roll Plan Software",
    cardTitle: "OptaCut",
    desc: " A fabric optimization tool that saves fabric at each stage in the supply chain.",
  },
  {
    to: "/optaplan",
    src: `${mediaUrl}/optaplan.png`,
    alt: "OptaPlan - Time and Action Caledar",
    cardTitle: "OptaPlan",
    desc: "A time and action calendar software to manage orders more efficiently.",
  },
];

const useStyles = makeStyles(_ => ({
  container: {
    ...container,
    backgroundColor: "#FFFFFF",
    padding: "40px 0px",
  },
  mlAuto,
  mrAuto,
  title,
  description,
  cardTitle: {
    ...cardTitle,
    fontSize: 21,
  },
  // content: {
  //   flexGrow: 1,
  //   overflow: "auto"
  // },
  textCenter: {
    textAlign: "center",
  },
  cardCategory: {
    color: "#333333 !important",
    marginTop: "10px",
  },
  cardDescription: {
    color: "#333333 !important",
    fontSize: "1.2em",
    lineHeight: "1.6em",
  },
  card: {
    textAlign: "center",
  },
  button: {
    marginTop: "1em",
    letterSpacing: 1,
    color: "#ffffff",
    backgroundColor: colors.skyBlue.main,
    padding: "0.6rem 1.5rem",
    "&:hover,&:focus": {
      color: "white",
      backgroundColor: colors.skyBlue.light,
    },
  },
}));

const Solutions = () => {
  const classes = useStyles();

  const mediaUrl = `${appUrl}/media/home/products`;

  const list = productList(mediaUrl);

  return (
    <div className={classes.content}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12} sm={8} md={8} className={`${classes.mlAuto} ${classes.mrAuto} ${classes.textCenter}`}>
            <h2 className={classes.title}>Our Solutions</h2>
          </GridItem>
        </GridContainer>
        <GridContainer justify="center" spacing={5}>
          {list.map((product, idx) => (
            <GridItem xs={12} sm={4} md={4} key={idx}>
              <Card plain className={classes.card}>
                <Link to={product.to}>
                  <CardHeader plain image>
                    <img src={product.src} alt={product.alt} style={{ opacity: 0.8 }} />
                  </CardHeader>
                </Link>
                <CardBody>
                  <Link to={product.to}>
                    <h1 className={classes.cardTitle}>{product.cardTitle}</h1>
                  </Link>
                  <p className={classes.cardDescription}>{product.desc}</p>
                  <Button component={Link} to={product.to} round className={classes.button}>
                    Learn More
                  </Button>
                </CardBody>
              </Card>
            </GridItem>
          ))}
        </GridContainer>
      </div>
    </div>
  );
};

export default Solutions;
