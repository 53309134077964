/* eslint-disable */
import React from "react";
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react components for routing our app without refresh
import { Link, withRouter } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Apps from "@material-ui/icons/Apps";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import ViewDay from "@material-ui/icons/ViewDay";
import Dns from "@material-ui/icons/Dns";
import Build from "@material-ui/icons/Build";
import ListIcon from "@material-ui/icons/List";
import People from "@material-ui/icons/People";
import Assignment from "@material-ui/icons/Assignment";
import MonetizationOn from "@material-ui/icons/MonetizationOn";
import Chat from "@material-ui/icons/Chat";
import Call from "@material-ui/icons/Call";
import ViewCarousel from "@material-ui/icons/ViewCarousel";
import AccountBalance from "@material-ui/icons/AccountBalance";
import ArtTrack from "@material-ui/icons/ArtTrack";
import ViewQuilt from "@material-ui/icons/ViewQuilt";
import LocationOn from "@material-ui/icons/LocationOn";
import Fingerprint from "@material-ui/icons/Fingerprint";
import AttachMoney from "@material-ui/icons/AttachMoney";
import Store from "@material-ui/icons/Store";
import AccountCircle from "@material-ui/icons/AccountCircle";
import PersonAdd from "@material-ui/icons/PersonAdd";
import Layers from "@material-ui/icons/Layers";
import ShoppingBasket from "@material-ui/icons/ShoppingBasket";
import LineStyle from "@material-ui/icons/LineStyle";

// core components
import Button from "components/Button";
import CustomDropdown from "components/CustomDropdown";

// ##############################
// // // HeaderLinks styles
// #############################

const headerLinksStyle = theme => ({
  list: {
    [theme.breakpoints.up("md")]: {
      WebkitBoxAlign: "center",
      MsFlexAlign: "center",
      alignItems: "center",
      WebkitBoxOrient: "horizontal",
      WebkitBoxDirection: "normal",
      MsFlexDirection: "row",
      flexDirection: "row",
    },
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
    marginTop: "0px",
    display: "flex",
    paddingLeft: "0",
    marginBottom: "0",
    listStyle: "none",
    padding: "0",
  },
  listItem: {
    float: "left",
    color: "inherit",
    position: "relative",
    display: "block",
    width: "auto",
    margin: "0",
    padding: "0",
    [theme.breakpoints.down("sm")]: {
      "& ul": {
        maxHeight: "400px",
        overflow: "scroll",
      },
      width: "100%",
      "&:after": {
        width: "calc(100% - 30px)",
        content: '""',
        display: "block",
        height: "1px",
        marginLeft: "15px",
        backgroundColor: "#e5e5e5",
      },
    },
  },
  navLink: {
    color: "inherit",
    position: "relative",
    padding: "0.6rem 1rem",
    fontWeight: "400",
    letterSpacing: 1,
    fontSize: "14px",
    textTransform: "uppercase",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex",
    "&:hover,&:focus": {
      color: "#00ADDA",
    },
    "& .fab,& .far,& .fal,& .fas,& .material-icons": {
      position: "relative",
      top: "2px",
      marginTop: "-4px",
      marginRight: "4px",
      marginBottom: "0px",
      fontSize: "1.25rem",
    },
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 30px)",
      marginLeft: "15px",
      marginBottom: "8px",
      marginTop: "8px",
      textAlign: "left",
      "& > span:first-child": {
        justifyContent: "flex-start",
      },
    },
    "& svg": {
      marginRight: "3px",
      width: "20px",
      height: "20px",
    },
  },
  navLinkActive: {
    "&, &:hover, &:focus,&:active ": {
      color: "#1d9cd9",
    },
  },
  navAction: {
    [theme.breakpoints.up("md")]: {
      marginLeft: "2em",
    },

    color: "#FFFFFF",
    backgroundColor: "#1d9cd9",
    "&:hover,&:focus": {
      color: "white",
      backgroundColor: "#42bcee",
    },
  },
  dropdownIcons: {
    width: "24px",
    height: "24px",
    marginRight: "14px",
    opacity: "0.8",
    marginTop: "-4px",
    top: "1px",
    verticalAlign: "middle",
    fontSize: "24px",
    position: "relative",
    color: "#222b34",
  },
  dropdownLink: {
    fontSize: "1.1em",
    "&,&:hover,&:focus": {
      color: "#000",
      textDecoration: "none",
      display: "flex",
      padding: "0.75rem 1.25rem 0.75rem 0.75rem",
    },
  },
});

const HeaderLinks = props => {
  const { classes, location, dropdownHoverColor, handleDrawerToggle } = props;
  return (
    <List className={classes.list}>
      {props.links.map(({ to, name, action, links }, idx) =>
        links ? (
          <ListItem key={idx} className={classes.listItem}>
            <CustomDropdown
              noLiPadding
              navDropdown
              hoverColor={dropdownHoverColor}
              buttonText={name}
              buttonProps={{
                className: classNames({
                  [classes.navLink]: true,
                  [classes.navLinkActive]: links.map(e => e.to).includes(`/${location.pathname.split("/")[1]}`),
                }),
                color: "transparent",
              }}
              // buttonIcon={Apps}
              dropdownList={links.map(({ to, name, icon }, index) => (
                <Link key={index} to={to} className={classes.dropdownLink} onClick={handleDrawerToggle}>
                  {icon && React.cloneElement(icon, { className: classes.dropdownIcons })}
                  {name}
                </Link>
              ))}
            />
          </ListItem>
        ) : (
          <ListItem key={idx} className={classes.listItem} onClick={handleDrawerToggle}>
            <Button
              component={Link}
              to={to}
              color="transparent"
              className={classNames({
                [classes.navLink]: true,
                [classes.navAction]: action === true,
                [classes.navLinkActive]: to === `/${location.pathname.split("/")[1]}`,
              })}>
              {name}
            </Button>
          </ListItem>
        )
      )}
    </List>
  );
};

HeaderLinks.defaultProps = {
  links: [],
  dropdownHoverColor: "primary",
};

HeaderLinks.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string,
      name: PropTypes.string,
      links: PropTypes.arrayOf(
        PropTypes.shape({
          to: PropTypes.string,
          name: PropTypes.string,
        })
      ),
    })
  ),
  dropdownHoverColor: PropTypes.oneOf(["dark", "primary", "info", "success", "warning", "danger", "rose"]),
};

export default withRouter(withStyles(headerLinksStyle)(HeaderLinks));
