import 'babel-polyfill'; 
import 'react-app-polyfill/ie11';
import React from 'react';
import { render } from "react-dom";
import App from './js/App';
import * as serviceWorker from './serviceWorker';
import "assets/scss/material-kit-pro-react.css?v=1.2.0";

render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
