import createMuiTheme from "@material-ui/core/styles/createMuiTheme";

/**
 * Formula for Font Size
 *
 * font-size: calc([minimum size] + ([maximum size] - [minimum size]) * ((100vw - [minimum viewport width]) / ([maximum viewport width] - [minimum viewport width])));
 *
 */

const defaultFontFamily = "'Montserrat',sans-serif";

const titleColor = "#1d2b36";

const bodyColor = "#4d4b6a";

const theme = createMuiTheme({
  props: {
    MuiTypography: {
      variantMapping: {
        h1: "h1",
        h2: "h1",
        h3: "h1",
        h4: "h2",
        h5: "h2",
        h6: "h2",
        subtitle1: "h2",
        subtitle2: "h2",
        body1: "span",
        body2: "span",
      },
    },
  },
  typography: {
    fontFamily: defaultFontFamily,
    titleColor,
    bodyColor,
    h1: {
      //xlarge
      fontFamily: defaultFontFamily,
      letterSpacing: "normal",
      fontWeight: "bold",
      color: titleColor,
      lineHeight: 1.1,
      fontSize: "54px",
      "@media (min-width: 320px)": {
        fontSize: "calc(54px + 42 * ((100vw - 320px) / 960))",
      },
      "@media (min-width: 1280px)": {
        fontSize: "96px",
      },
    },
    h2: {
      //large
      fontFamily: defaultFontFamily,
      letterSpacing: "normal",
      fontWeight: "bold",
      color: titleColor,
      lineHeight: 1.15,
      fontSize: "36px",
      "@media (min-width: 320px)": {
        fontSize: "calc(36px + 24 * ((100vw - 320px) / 960))",
      },
      "@media (min-width: 1280px)": {
        fontSize: "60px",
      },
    },
    h3: {
      //medium
      fontFamily: defaultFontFamily,
      letterSpacing: "normal",
      fontWeight: "bold",
      color: titleColor,
      lineHeight: 1.2,
      fontSize: "30px",
      "@media (min-width: 320px)": {
        fontSize: "calc(30px + 18 * ((100vw - 320px) / 960))",
      },
      "@media (min-width: 1280px)": {
        fontSize: "48px",
      },
    },
    h4: {
      //small
      fontFamily: defaultFontFamily,
      letterSpacing: "normal",
      fontWeight: "bold",
      color: titleColor,
      lineHeight: 1.5,
      fontSize: "24px",
      "@media (min-width: 320px)": {
        fontSize: "calc(24px + 12 * ((100vw - 320px) / 960))",
      },
      "@media (min-width: 1280px)": {
        fontSize: "36px",
      },
    },
    h5: {
      //xsmall
      fontFamily: defaultFontFamily,
      letterSpacing: "normal",
      fontWeight: "bold",
      color: titleColor,
      lineHeight: 1.334,
      fontSize: "18px",
      "@media (min-width: 320px)": {
        fontSize: "calc(18px + 6 * ((100vw - 320px) / 960))",
      },
      "@media (min-width: 1280px)": {
        fontSize: "24px",
      },
    },
    h6: {
      //xxsmal
      fontFamily: defaultFontFamily,
      letterSpacing: "normal",
      fontWeight: "bold",
      color: titleColor,
      lineHeight: 1.6,
      fontSize: "16px",
      "@media (min-width: 320px)": {
        fontSize: "calc(16px + 4 * ((100vw - 320px) / 960))",
      },
      "@media (min-width: 1280px)": {
        fontSize: "20px",
      },
    },
    subtitle1: {
      fontFamily: defaultFontFamily,
      fontWeight: "normal",
      fontSize: "1.25rem",
      lineHeight: 1.6,
      letterSpacing: "normal",
      color: bodyColor,
    },
    subtitle2: {
      fontFamily: defaultFontFamily,
      fontWeight: "normal",
      fontSize: "1.125rem",
      lineHeight: 1.6,
      letterSpacing: "normal",
      color: bodyColor,
    },
    body1: {
      fontFamily: defaultFontFamily,
      fontWeight: "normal",
      fontSize: "1rem",
      lineHeight: 1.6,
      letterSpacing: "normal",
      color: bodyColor,
    },
    body2: {
      fontFamily: defaultFontFamily,
      fontWeight: "normal",
      fontSize: "0.875rem",
      lineHeight: 1.6,
      letterSpacing: "normal",
      color: bodyColor,
    },
  },
});

export default theme;

// const defaultTypography = {
//     fontFamily: "'Montserrat',sans-serif",
//     fontSize: 14,
//     fontWeightLight: 300,
//     fontWeightRegular: 400,
//     fontWeightMedium: 500,
//     fontWeightBold: 700,
//     h1: {
//       fontFamily: "'Montserrat',sans-serif",
//       fontWeight: 300,
//       fontSize: "6rem",
//       lineHeight: 1.167,
//       letterSpacing: "-0.01562em",
//     },
//     h2: {
//       fontFamily: "'Montserrat',sans-serif",
//       fontWeight: 300,
//       fontSize: "3.75rem",
//       lineHeight: 1.2,
//       letterSpacing: "-0.00833em",
//     },
//     h3: {
//       fontFamily: "'Montserrat',sans-serif",
//       fontWeight: 400,
//       fontSize: "3rem",
//       lineHeight: 1.167,
//       letterSpacing: "0em",
//     },
//     h4: {
//       fontFamily: "'Montserrat',sans-serif",
//       fontWeight: 400,
//       fontSize: "2.125rem",
//       lineHeight: 1.235,
//       letterSpacing: "0.00735em",
//     },
//     h5: {
//       fontFamily: "'Montserrat',sans-serif",
//       fontWeight: 400,
//       fontSize: "1.5rem",
//       lineHeight: 1.334,
//       letterSpacing: "0em",
//     },
//     h6: {
//       fontFamily: "'Montserrat',sans-serif",
//       fontWeight: 500,
//       fontSize: "1.25rem",
//       lineHeight: 1.6,
//       letterSpacing: "0.0075em",
//     },
//     subtitle1: {
//       fontFamily: "'Montserrat',sans-serif",
//       fontWeight: 400,
//       fontSize: "1rem",
//       lineHeight: 1.75,
//       letterSpacing: "0.00938em",
//     },
//     subtitle2: {
//       fontFamily: "'Montserrat',sans-serif",
//       fontWeight: 500,
//       fontSize: "0.875rem",
//       lineHeight: 1.57,
//       letterSpacing: "0.00714em",
//     },
//     body1: {
//       fontFamily: "'Montserrat',sans-serif",
//       fontWeight: 400,
//       fontSize: "1rem",
//       lineHeight: 1.5,
//       letterSpacing: "0.00938em",
//     },
//     body2: {
//       fontFamily: "'Montserrat',sans-serif",
//       fontWeight: 400,
//       fontSize: "0.875rem",
//       lineHeight: 1.43,
//       letterSpacing: "0.01071em",
//     },
//   },
