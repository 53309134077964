import ReactGA from "react-ga";
import React, { Component } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles/";

import store from "store";

import AppHeader from "./AppHeader";
import Main from "./Main";
import AppFooter from "./AppFooter";
import theme from "./theme";

export const rootUrl = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ":" + window.location.port : "");
// export const rootUrl = `http://${window.location.hostname}:8016`;
// export const rootUrl = "https://jaza-soft.com";
export const oauthUrl = `${rootUrl}/oauth/token`;
export const appUrl = `${rootUrl}/api`;

/**
 * @deprecated
 */
export const httpUrl = `${rootUrl}/api`;

ReactGA.initialize("UA-132869933-1");
ReactGA.pageview(window.location.pathname + window.location.search);

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <MuiThemeProvider theme={theme}>
            <React.Fragment>
              <CssBaseline />
              <div>
                <AppHeader />

                <Main />

                <AppFooter />
              </div>
            </React.Fragment>
          </MuiThemeProvider>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
