import React from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// import TrendingUp from "@material-ui/icons/TrendingUp";
// core components
import GridContainer from "components/GridContainer.jsx";
import GridItem from "components/GridItem.jsx";
import Card from "components/Card.jsx";
import CardHeader from "components/CardHeader.jsx";
import Info from "components/Typography/Info.jsx";
// import Danger from "components/Typography/Danger.jsx";

// import cardBlog4 from "assets/img/examples/card-blog4.jpg";
// import office2 from "assets/img/office2.jpg";

import { fetchBlogs } from "actions/blogAction";

import {
  container,
  title,
  cardTitle,
  coloredShadow,
  description,
  mlAuto,
  mrAuto,
} from "assets/jss/material-kit-pro-react.jsx";
import Hidden from "@material-ui/core/Hidden";
// import getDuration from "utils/getDuration";

export const removeTags = input => (input ? input.replace(/<[^>]+>/gm, "") : "");

const getDescription = body => {
  body = removeTags(body);
  body = body.substring(0, 225);
  body = body.substring(0, body.lastIndexOf(" "));
  return body + " ...";
};

const styles = {
  container,
  title,
  coloredShadow,
  mlAuto,
  mrAuto,
  cardTitle: {
    ...cardTitle,
    fontSize: "21px",
  },
  blog: {
    padding: "80px 0",
  },
  cardCategory: {
    marginBottom: "0",
    marginTop: "10px",
    "& svg,& .fab,& .fas,& .far,& .fal,& .material-icons": {
      position: "relative",
      top: "8px",
      lineHeight: "0",
    },
  },
  description: {
    ...description,
    lineHeight: "1.313rem",
    fontSize: "1.1em",
    color: "rgba(29, 43, 54, 0.8)",
  },
  readMore: {
    color: "#1d9cd9",
  },
  author: {
    "& a": {
      color: "#3C4858",
      textDecoration: "none",
    },
  },
  card: {
    marginBottom: "80px",
  },
  textCenter: {
    textAlign: "center",
  },
};

class SectionBlogs extends React.Component {
  componentDidMount() {
    this.props.fetchBlogs(0, 2);
  }

  render() {
    const {
      classes,
      blog: { blogs },
    } = this.props;

    const data =
      blogs &&
      Object.keys(blogs)
        .map(id => blogs[id])
        .sort((a, b) => b.id - a.id)
        .slice(0, 2);
    return (
      <div style={{ backgroundColor: "#F7F9FA" }}>
        <div className={classes.blog}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem md={8} className={classNames(classes.mlAuto, classes.mrAuto, classes.textCenter)}>
                <h2 className={classes.title}>Blogs</h2>
              </GridItem>
              <GridItem xs={12} sm={10} md={10} className={`${classes.mlAuto} ${classes.mrAuto}`}>
                {data.map((blog, idx) => (
                  <Card key={blog.id} plain blog className={classes.card}>
                    <GridContainer justify="space-between">
                      {idx % 2 === 0 && (
                        <GridItem xs={12} sm={5} md={5}>
                          <CardHeader image plain>
                            <Link to={`/blogs/${blog.urlId}-${blog.id}`}>
                              <img src={blog.blogThumbnail} alt={blog.title} />
                            </Link>
                          </CardHeader>
                        </GridItem>
                      )}
                      <Hidden smUp>
                        {idx % 2 === 1 && (
                          <GridItem xs={12} sm={5} md={5}>
                            <CardHeader image plain>
                              <Link to={`/blogs/${blog.urlId}-${blog.id}`}>
                                <img src={blog.blogThumbnail} alt={blog.title} />
                              </Link>
                            </CardHeader>
                          </GridItem>
                        )}
                      </Hidden>
                      <GridItem xs={12} sm={6} md={6}>
                        <Info>
                          <h6 className={classes.cardCategory}>{blog.category}</h6>
                        </Info>
                        <h1 className={classes.cardTitle}>
                          <Link to={`/blogs/${blog.urlId}-${blog.id}`}>{blog.title}</Link>
                        </h1>
                        <p className={classes.description}>
                          {getDescription(blog.body)}
                          <Link to={`/blogs/${blog.urlId}-${blog.id}`} className={classes.readMore}>
                            {" "}
                            Read More
                          </Link>
                        </p>
                        <p className={classes.author}>
                          by{" "}
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            <b>{blog.author}</b>
                          </a>{" "}
                          {/* , {getDuration(blog.createdAt)} */}
                        </p>
                      </GridItem>
                      <Hidden xsDown>
                        {idx % 2 === 1 && (
                          <GridItem xs={12} sm={5} md={5}>
                            <CardHeader image plain>
                              <Link to={`/blogs/${blog.urlId}-${blog.id}`}>
                                <img src={blog.blogThumbnail} alt={blog.title} />
                              </Link>
                            </CardHeader>
                          </GridItem>
                        )}
                      </Hidden>
                    </GridContainer>
                  </Card>
                ))}
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({ blog: state.blog });

export default connect(mapStateToProps, { fetchBlogs })(withStyles(styles)(SectionBlogs));
