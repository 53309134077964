import axios from "axios";
import { oauthUrl, httpUrl } from "../App";
import {appId} from "../constants";

export const LOGIN_PROGRESS = "LOGIN_PROGRESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

//paylaod: {username, password}
export const login = (username, password) => {
  return dispatch => {
    dispatch({ type: LOGIN_PROGRESS });
    const config = {
      url: oauthUrl,
      method: "post",
      params: { username, password, grant_type: "password" },
      headers: {
        Accept: "application/json",
        Authorization: `Basic ${btoa("client:secret")}`
      }
    };
    axios
      .request(config)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        if (response.data && response.data["access_token"]) {
          const token = response.data["access_token"];
          localStorage.setItem(`access_token_${appId}`, token);
          dispatch({ type: LOGIN_SUCCESS, payload: { ...response.data } });
        }
        return Promise.resolve(response);
      })
      .catch(error => {
        dispatch({ type: LOGIN_FAILURE });
        if (error.response && error.response.status === 400) {
          alert("Incorrect username or password.");
        }
        return Promise.reject(error);
      });
  };
};

export const LOGOUT = "LOGOUT";

export const logout = () => {
  return { type: LOGOUT };
};

export const FORGOT_PASSWORD_PROGRESS = "FORGOT_PASSWORD_PROGRESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";

export const forgotPassword = (username) => {
  return (dispatch) => {
    dispatch({type: FORGOT_PASSWORD_PROGRESS})
    const config = {
      url: `${httpUrl}/users/forgotPassword`,
      method: "patch",
      params: { username },
      headers: {
        Accept: "application/json"
      }
    };
    return axios
      .request(config)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        } else if (response.data) {
          alert(response.data.message);
          dispatch({type: FORGOT_PASSWORD_SUCCESS});
        } 
        return Promise.resolve(response);
      })
      .catch(error => {
        dispatch({type: FORGOT_PASSWORD_FAILURE});
        return Promise.reject(error);
      });
  };
}
