/*eslint-disable*/
import React from "react";
import { withRouter, Link } from "react-router-dom";
import PropTypes from "prop-types";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import withStyles from "@material-ui/core/styles/withStyles";

import Button from "components/Button";

import { container, primaryColor } from "assets/jss/material-kit-pro-react.jsx";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";

const footerStyle = theme => ({
  block: {
    color: "inherit",
    padding: "0.5em 1em",
    fontWeight: "500",
    fontSize: "14px",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block",
    "&:hover": {
      color: "#1d9cd9",
    },
  },
  left: {
    [theme.breakpoints.up("md")]: {
      float: "left !important",
      backgroundColor: "green",
    },
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContents: "center",
    },
  },
  right: {
    padding: "15px 0",
    margin: "0",
    float: "right!important",
  },
  footer: {
    padding: "0.9375rem 0",
    textAlign: "center",
    display: "flex",
    zIndex: "2",
    position: "relative",
  },
  a: {
    color: primaryColor,
    textDecoration: "none",
    backgroundColor: "transparent",
  },
  footerWhiteFont: {
    "&,&:hover,&:focus": {
      color: "#FFFFFF",
    },
  },
  container: {
    ...container,
    display: "flex",
    alignItems: "center",
  },
  socialContainer: {
    [theme.breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },
  },
  listContainer: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center",
    },
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0",
    textAlign: "left",
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto",
  },
  icon: {
    width: "18px",
    height: "18px",
    position: "relative",
    top: "3px",
  },
  copyright: {
    padding: "0.5em 1em",
    fontSize: 12,
    [theme.breakpoints.up("md")]: {
      textAlign: "left",
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
    color: "rgba(20,20,20,0.5)",
  },
  rightLinks: {
    "& ul": {
      marginBottom: 0,
      padding: 0,
      listStyle: "none",
      "& li": {
        display: "inline-block",
      },
      "& a": {
        display: "block",
      },
    },
    "& i": {
      fontSize: "32px",
    },
  },
});

const Social = ({ classes }) => {
  return (
    <Grid item xs={12} md={6} className={classes.socialContainer}>
      <div className={classes.rightLinks}>
        <div>
          <ul>
            <li>
              <Button
                aria-label="LinkedIn"
                href="https://www.linkedin.com/company/jazasoftware"
                target="_blank"
                rel="noreferrer"
                color="linkedin"
                justIcon
                simple>
                <i className="fab fa-linkedin" />
              </Button>
            </li>
            <li>
              <Button
                aria-label="Facebook"
                href="https://www.facebook.com/jazasoft2017"
                target="_blank"
                rel="noreferrer"
                color="facebook"
                justIcon
                simple>
                <i className="fab fa-facebook" />
              </Button>
            </li>
            <li>
              <Button
                aria-label="Youtube"
                href="https://www.youtube.com/channel/UCxEq-8VWo7GZwUxZeVmY-_w"
                target="_blank"
                rel="noreferrer"
                color="youtube"
                justIcon
                simple>
                <i className="fab fa-youtube" />
              </Button>
            </li>
            <li>
              <Button
                aria-label="Instagram"
                href="https://www.instagram.com/jazasoftware/"
                target="_blank"
                color="instagram"
                justIcon
                simple>
                <i className="fab fa-instagram" />
              </Button>
            </li>

            <li>
              <Button
                aria-label="Twitter"
                href="https://twitter.com/jazasoftware"
                target="_blank"
                rel="noreferrer"
                color="twitter"
                justIcon
                simple>
                <i className="fab fa-twitter" />
              </Button>
            </li>
          </ul>
        </div>
      </div>
    </Grid>
  );
};

const Copyright = ({ classes }) => {
  return (
    <Grid container item xs={12} md={6}>
      <Grid item xs={12} className={classes.listContainer}>
        <List className={classes.list}>
          {/* <ListItem className={classes.inlineBlock}>
            <a
              href="https://www.creative-tim.com/"
              className={classes.block}
              target="_blank"
            >
              Terms of use
            </a>
          </ListItem> */}
          <ListItem className={classes.inlineBlock}>
            <Link to="/privacyPolicy" className={classes.block}>
              Privacy policy
            </Link>
          </ListItem>
          <ListItem className={classes.inlineBlock}>
            <p
              className={classes.block}
              onClick={() => {
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
              }}>
              Back to top
            </p>
          </ListItem>
        </List>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.copyright}>
          <p>Copyright &copy; {1900 + new Date().getYear()} Jaza Software OPC Pvt. Ltd.</p>
        </div>
      </Grid>
    </Grid>
  );
};

const Footer = props => {
  if (props.location.pathname === "/login") return null;

  const { classes } = props;
  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        <Grid container justify="space-between">
          <Hidden mdUp>
            <Social classes={classes} />
          </Hidden>
          <Copyright classes={classes} />
          <Hidden smDown>
            <Social classes={classes} />
          </Hidden>
        </Grid>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  whiteFont: PropTypes.bool,
};

export default withRouter(withStyles(footerStyle)(Footer));
