import { SAVING_START, SAVING_END } from "actions/savingAction";

export default (state = false, { type }) => {
  switch (type) {
    case SAVING_START:
      return true;
    case SAVING_END:
      return false;
    default:
      return state;
  }
};
