import {
  BLOG_FETCH_PROGRESS,
  BLOG_FETCH_SUCCESS,
  BLOG_FETCH_FAILURE,
  BLOG_GET_ONE_PROGRESS,
  BLOG_GET_ONE_SUCCESS,
  BLOG_GET_ONE_FAILURE,
  BLOG_CREATE_PROGRESS,
  BLOG_CREATE_SUCCESS,
  BLOG_CREATE_FAILURE,
  BLOG_UPDATE_PROGRESS,
  BLOG_UPDATE_SUCCESS,
  BLOG_UPDATE_FAILURE,
} from "actions/blogAction";
// import cloneDeep from "lodash/cloneDeep";

const initialState = {
  busy: false,
  blogs: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case BLOG_GET_ONE_PROGRESS:
    case BLOG_FETCH_PROGRESS:
    case BLOG_CREATE_PROGRESS:
    case BLOG_UPDATE_PROGRESS:
      return { ...state, busy: true };
    case BLOG_FETCH_SUCCESS:
      const { blogs } = action.payload;
      if (blogs) {
        return {
          ...state,
          blogs: {
            ...state.blogs,
            ...blogs.reduce((prev, e) => ({ ...prev, [e.id]: e }), {}),
          },
          busy: false,
        };
      }
      break;
    case BLOG_GET_ONE_SUCCESS:
      const { blog } = action.payload;
      if (blog) {
        return {
          ...state,
          blogs: {
            ...state.blogs,
            [blog.id]: blog,
          },
          busy: false,
        };
      }
      break;
    case BLOG_CREATE_SUCCESS:
      if (action.payload) {
        return {
          ...state,
          blogs: { ...state.blogs, [action.payload.id]: action.payload },
          busy: false,
        };
      }
      break;
    case BLOG_UPDATE_SUCCESS:
      if (action.payload) {
        return {
          ...state,
          blogs: { ...state.blogs, [action.payload.id]: action.payload },
          busy: false,
        };
      }
      break;
    case BLOG_GET_ONE_FAILURE:
    case BLOG_FETCH_FAILURE:
    case BLOG_CREATE_FAILURE:
    case BLOG_UPDATE_FAILURE:
      return { ...state, busy: false };
    default:
  }
  return state;
};
