import isEqual from "lodash/isEqual";
import {
  LOGIN_PROGRESS,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  FORGOT_PASSWORD_PROGRESS,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE
} from "actions/authAction";

const loadState = appId => {
  try {
    const serializedState = localStorage.getItem(`authState_${appId}`);
    if (serializedState === null) {
      return { isLoggedIn: false };
    }
    return JSON.parse(serializedState);
  } catch (err) {
    console.log(err);
    return { isLoggedIn: false };
  }
};

const saveState = (appId, state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(`authState_${appId}`, serializedState);
  } catch (err) {
    console.log(err);
  }
};

// const initialState = {
//   busy: false,
//   isLoggedIn: false,
//   ...loginPayload
// };

export default appId => (
  previousState = loadState(appId),
  { type, payload }
) => {
  let newState;
  switch (type) {
    case LOGIN_PROGRESS:
    case FORGOT_PASSWORD_PROGRESS:
      newState = { ...previousState, busy: true };
      break;
    case LOGIN_FAILURE:
    case FORGOT_PASSWORD_FAILURE:
      newState = { ...previousState, busy: false };
      break;
    case LOGIN_SUCCESS:
      newState = {
        ...previousState,
        ...payload,
        isLoggedIn: true,
        busy: false
      };
      break;
    case FORGOT_PASSWORD_SUCCESS:
      newState = { ...previousState, busy: false };
      break;
    case LOGOUT:
      delete localStorage[`access_token_${appId}`];
      newState = { isLoggedIn: false };
      break;
    default:
      newState = previousState;
  }
  if (!isEqual(previousState, newState)) {
    saveState(appId, newState);
  }
  return newState;
};
