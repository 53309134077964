import { combineReducers } from "redux";
import { appId } from "../constants";

import blogReducer from "./blogReducer";
import authReducer from "./authReducer";
import loadingReducer from "./loadingReducer";
import savingReducer from "./savingReducer";

export default combineReducers({
  blog: blogReducer,
  auth: authReducer(appId),
  loading: loadingReducer,
  saving: savingReducer,
});
