import React, { Component } from "react";
// import classNames from "classnames";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";

import Parallax from "components/Parallax";
import GridContainer from "components/GridContainer";
import GridItem from "components/GridItem";
import CallToAction from "components/CallToAction";

import SectionBlog from "./sections/SectionBlog";
import SectionTestimonials from "./sections/SectionTestimonials";
import SectionSolutions from "./sections/SectionSolutions";

import { container, title } from "assets/jss/material-kit-pro-react.jsx";

import { appUrl } from "../../App";

const pathMedia = `${appUrl}/media/optafloor`;

const styles = theme => ({
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3",
  },
  container: {
    ...container,
    zIndex: "12",
    color: "#FFFFFF",
  },
  title: {
    ...title,
    display: "block",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    color: "#FFFFFF",
    textDecoration: "none",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2em",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "2em",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "2.4em",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "3em",
    },
  },
  subtitle: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2em",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.2em",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.8em",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "2em",
    },
  },
  desc: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "1em",
    },
  },
  alignCenter: {
    textAlign: "center",
  },
  button: {
    letterSpacing: 1,
    color: "#ffffff",
    backgroundColor: "#1d9cd9",
    padding: "0.6rem 1.5rem",
    fontSize: 12,
    "&:hover,&:focus": {
      color: "white",
      backgroundColor: "#42bcee",
    },
  },
});

// window.addEventListener("resize", (e) => {
//   console.log({iw: window.innerWidth, ow: window.outerWidth});
// });

class Home extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Jaza Software - Digital solutions for apparel industry</title>
          <link rel="canonical" href={appUrl} />
          <meta
            name="description"
            content="Software Solutions for garment manufacturers to increase operational efficiency. Leverage technology to transform your business and stay competitive."
          />

          <meta property="og:title" content="Jaza Software" />
          <meta
            property="og:description"
            content="Software Solutions for garment manufacturers to increase operational efficiency. Leverage technology to transform your business and stay competitive."
          />
          <meta property="og:image" content={`${pathMedia}/logo-jaza.png`} />
          <meta property="og:url" content={appUrl} />
          <meta property="og:site_name" content="Jaza Software OPC Pvt. Ltd." />

          <meta name="twitter:url" content={appUrl} />
          <meta name="twitter:title" content="Jaza Software" />
          <meta
            name="twitter:description"
            content="Software Solutions for garment manufacturers to increase operational efficiency. Leverage technology to transform your business and stay competitive."
          />
          <meta name="twitter:image" content={`${pathMedia}/logo-jaza.png`} />
          <meta
            name="twitter:card"
            content="Software Solutions for garment manufacturers to increase operational efficiency. Leverage technology to transform your business and stay competitive."
          />
        </Helmet>
        <Parallax style={{ background: "#222b34" }} id="top">
          <div className={classes.container}>
            <GridContainer>
              <GridItem item xs={12} sm={12} md={6} style={{ alignSelf: "center" }}>
                <h1 className={classes.title}>Digital Innovation Company that transforms Businesses</h1>
              </GridItem>
              <GridItem item xs={12} sm={12} md={6} style={{ alignSelf: "center" }}>
                <h1 className={classes.title + " " + classes.subtitle}>
                  We provide cutting-edge software solutions to the apparel industry.
                </h1>
                <h4 className={classes.desc}>
                  We Love to help companies who are always looking for ways to improve themselves and embrace an
                  ever-changing world. We work as an extension of your team with tailored advice and support to help you
                  grow, optimize and innovate.
                </h4>
                <br />
                <Button component={Link} to="/optacut" href="#solutions" className={classes.button}>
                  Explore Our Solutions
                </Button>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <div className={classes.main}>
          <SectionSolutions id="solutions" />
          <SectionTestimonials />
          <SectionBlog />
          <CallToAction
            title="Get ahead in the competition with our digital solutions"
            actionLabel="Request a Demo"
            onClick={_ => this.props.history.push("/demo", { products: "Home Page Footer CTA Button" })}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Home);
