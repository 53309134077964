import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";

import Loadable from "react-loadable";

import Home from "./pages/home/Home";
import Loading from "./Loading";

// const Solutions = Loadable({
//   loader: () => import("./pages/solutions/Solutions"),
//   loading: Loading
// });
const OptaCut = Loadable({
  loader: () => import("./pages/solutions/optacut/OptaCut"),
  loading: Loading,
});
const SPMS = Loadable({
  loader: () => import("./pages/solutions/spms/SPMS"),
  loading: Loading,
});
const TNA = Loadable({
  loader: () => import("./pages/solutions/tna/TNA"),
  loading: Loading,
});
// const AboutUs = Loadable({
//   loader: () => import("./pages/about_us/AboutUs"),
//   loading: Loading,
// });
const Blogs = Loadable({
  loader: () => import("./pages/blog/Blogs"),
  loading: Loading,
});
const Blog = Loadable({
  loader: () => import("./pages/blog/Blog"),
  loading: Loading,
});
const BlogCreate = Loadable({
  loader: () => import("./pages/blog/BlogCreate"),
  loading: Loading,
});
const BlogEdit = Loadable({
  loader: () => import("./pages/blog/BlogEdit"),
  loading: Loading,
});
const Contact = Loadable({
  loader: () => import("./pages/contact/Contact"),
  loading: Loading,
});
const Demo = Loadable({
  loader: () => import("./pages/demo/Demo"),
  loading: Loading,
});
const Login = Loadable({
  loader: () => import("./pages/Login"),
  loading: Loading,
});
const PrivacyPlociy = Loadable({
  loader: () => import("./PrivacyPloicy"),
  loading: Loading,
});
const NotFound = Loadable({
  loader: () => import("./pages/NotFound"),
  loading: Loading,
});
const Files = Loadable({
  loader: () => import("./pages/file/Files"),
  loading: Loading,
});
const Typography = Loadable({
  loader: () => import("./pages/Typography"),
  loading: Loading,
});

class Main extends Component {
  render() {
    return (
      <main>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/login" component={Login} />
          <Route path="/optacut" component={OptaCut} />
          <Route path="/spms" component={SPMS} />
          <Route path="/optafloor" component={SPMS} />
          <Route path="/tna" component={TNA} />
          <Route path="/optaplan" component={TNA} />
          {/* <Route path="/solutions" component={Solutions} /> */}
          {/* <Route path="/about_us" component={AboutUs} /> */}
          <Route path="/blogs/create" component={BlogCreate} />
          <Route path="/blogs/:id/edit" component={BlogEdit} />
          <Route path="/blogs/:id" component={Blog} />
          <Route path="/blogs" component={Blogs} />
          <Route path="/media" component={Files} />
          <Route path="/contact" component={Contact} />
          <Route path="/demo" component={Demo} />
          <Route path="/privacyPolicy" component={PrivacyPlociy} />
          <Route path="/typography" component={Typography} />
          <Route component={NotFound} />
        </Switch>
      </main>
    );
  }
}

export default Main;
