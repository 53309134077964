import axios from "axios";

import { httpUrl } from "../App";
import { LOGOUT } from "./authAction";

import getHeaders from "../utils/getHeaders";

export const BLOG_FETCH_PROGRESS = "BLOG_FETCH_PROGRESS";
export const BLOG_FETCH_SUCCESS = "BLOG_FETCH_SUCCESS";
export const BLOG_FETCH_FAILURE = "BLOG_FETCH_FAILURE";

export const fetchBlogs = (page = 0, pageSize = 15, sort = "id,desc") => {
  return (dispatch) => {
    dispatch({ type: BLOG_FETCH_PROGRESS });

    axios
      .get(`${httpUrl}/blogs`, {
        params: { page, size: pageSize, sort },
      })
      .then((response) => {
        if (response && response.status === 200) {
          dispatch({
            type: BLOG_FETCH_SUCCESS,
            payload: { blogs: response.data && response.data.content },
          });
        }
        return Promise.resolve(response);
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: BLOG_FETCH_FAILURE });
        return Promise.reject(error);
      });
  };
};

export const BLOG_GET_ONE_PROGRESS = "BLOG_GET_ONE_PROGRESS";
export const BLOG_GET_ONE_SUCCESS = "BLOG_GET_ONE_SUCCESS";
export const BLOG_GET_ONE_FAILURE = "BLOG_GET_ONE_FAILURE";

export const getOneBlog = (id) => {
  // console.log(`getBlog: id = ${id}`);
  return (dispatch) => {
    dispatch({ type: BLOG_GET_ONE_PROGRESS });

    axios
      .get(`${httpUrl}/blogs/${id}`)
      .then((response) => {
        if (response && response.status === 200) {
          dispatch({
            type: BLOG_GET_ONE_SUCCESS,
            payload: { blog: response.data },
          });
        }
        return Promise.resolve(response);
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: BLOG_GET_ONE_FAILURE });
        return Promise.reject(error);
      });
  };
};

export const BLOG_CREATE_PROGRESS = "BLOG_CREATE_PROGRESS";
export const BLOG_CREATE_SUCCESS = "BLOG_CREATE_SUCCESS";
export const BLOG_CREATE_FAILURE = "BLOG_CREATE_FAILURE";

export const createBlog = (blog) => {
  return (dispatch) => {
    dispatch({ type: BLOG_CREATE_PROGRESS });

    axios
      .post(`${httpUrl}/blogs`, blog, { headers: getHeaders() })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          dispatch({ type: BLOG_CREATE_SUCCESS, payload: response.data });
        }
        return Promise.resolve(response);
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: LOGOUT });
        dispatch({ type: BLOG_CREATE_FAILURE });
        return Promise.reject(error);
      });
  };
};

export const BLOG_UPDATE_PROGRESS = "BLOG_UPDATE_PROGRESS";
export const BLOG_UPDATE_SUCCESS = "BLOG_UPDATE_SUCCESS";
export const BLOG_UPDATE_FAILURE = "BLOG_UPDATE_FAILURE";

export const updateBlog = (blog) => {
  return (dispatch) => {
    dispatch({ type: BLOG_UPDATE_PROGRESS });

    axios
      .put(`${httpUrl}/blogs/${blog.id}`, blog, { headers: getHeaders() })
      .then((response) => {
        if (response.status === 200) {
          dispatch({ type: BLOG_UPDATE_SUCCESS, payload: response.data });
        }
        return Promise.resolve(response);
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: LOGOUT });
        dispatch({ type: BLOG_UPDATE_FAILURE });
        return Promise.reject(error);
      });
  };
};
