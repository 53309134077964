import { FETCH_START, FETCH_END } from "actions/loadingAction";

export default (state = false, { type }) => {
  switch (type) {
    case FETCH_START:
      return true;
    case FETCH_END:
      return false;
    default:
      return state;
  }
};
